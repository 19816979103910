import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngTwoDNFTGame = loadable(() => import("../components/TwoDNFTGame/english.js"));
const ArabicTwoDNFTGame = loadable(() => import("../components/TwoDNFTGame/arabic.js"));
const ChineseTwoDNFTGame = loadable(() => import("../components/TwoDNFTGame/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "brand": "Softtik Technologies",
      "image": "https://softtik.com/images/nft-collection/invest-section-8.webp",
      "name": "Leading 2D NFT Game Development Company",
      "description": "2D NFT game development company, Softtik, is a trusted name with years of experience. Get in touch for high-end NFT game development solutions.",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "102"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is a 2D NFT game developer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>2D NFT game developer is a person with in-depth knowledge of NFTs, blockchain, and gaming. He also knows how to integrate these technologies to create the best NFT gaming solutions.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Is there a market for 2D NFT games?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Yes, there’s a huge market demand for the 2D NFT games. Players worldwide play these games to earn in-game tokens and NFTs as rewards.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How much does 2D NFT game development cost?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>The 2D NFT game development cost depends on your requirements and necessary features. You can reach out to <b>Softtik Technologies</b> and submit your requirements to know about the estimated cost for 2D NFT Game development.</p>"
        }
      }
      ]
    }
  ]
}

export class TwoDNFTGame extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English",
    }
  };

  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    // else if (region == 'sa') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    
    let { lang } = this.state;

    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/2d-nft-game-development-services/"
            title="2D NFT Game Development Company - Softtik Technologies"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-8.webp"
            description="2D NFT game development company, Softtik, is a trusted name with years of experience. Get in touch for high-end NFT game development solutions."
          />
          <main>
            <div id="wrapper">
              <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicTwoDNFTGame />
                  : lang == "China"
                    ? <ChineseTwoDNFTGame />
                    : <EngTwoDNFTGame />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};
export default TwoDNFTGame;